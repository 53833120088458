<template>
  <div class="article_center">
    <!-- <div class="form_info">
      <el-form ref="form" label-width="80px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="文章类型:" prop="">
              <el-radio-group v-model="queryInfo.questionnaireId" @change="search">
                <el-radio-button label="">全部 </el-radio-button>
                <el-radio-button label="2">考察快讯 </el-radio-button>
                <el-radio-button label="3">企业快讯 </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div> -->

    <div class="tab_txt">
      <div class="input-with">
        <el-input
          placeholder="请输入文章名称"
          v-model="queryInfo.articleName"
          class="input-with-select"
        >
          <el-button class="seach" slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-button type="primary" @click="addBtn">新增</el-button>
      </div>
      <div class="in_table">
        <el-table
          v-loading="loading"
          :data="listData"
          style="width: 100%"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          @sort-change="sortChanged"
        >
          <el-table-column label="编号" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ 'G00' + row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="文章名称" prop="articleName" align="center"> </el-table-column>
          <el-table-column label="封面图" prop="surfaceDrawing" align="center" width="100">
            <template v-slot="{ row }">
              <div class="img_box">
                <img :src="row.surfaceDrawing" alt="" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="文章来源" prop="articleType" align="center">
            <template v-slot="{ row }">
              <span>{{ row.articleType === '1' ? 'CUC商盟' : '长三角商会秘书长联盟' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="查看人数" prop="viewNumber" align="center" sortable>
          </el-table-column>
          <el-table-column label="分享人数" prop="shareNumber" align="center" sortable>
          </el-table-column>
          <el-table-column label="操作人" prop="updateName" align="center"></el-table-column>
          <el-table-column
            label="操作时间"
            prop="updateTime"
            align="center"
            sortable
          ></el-table-column>
          <el-table-column label="操作" align="center" width="160px">
            <template v-slot="{ row }">
              <el-button
                :class="!row.isShelf ? 'up_btn' : 'down_btn'"
                size="mini"
                plain
                @click="setShelfBtn(row)"
                >{{ row.isShelf ? '下架' : '上架' }}</el-button
              >
              <el-button style="color: #146aff" type="text" @click="editBtn(row)">编辑</el-button>
              <el-button style="color: #ff4646" type="text" @click="setFreezeBtn(row)"
                >{{ row.isFreeze ? '激活' : '冻结' }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

  <script>
import { articleList, addArticle, updateType, getPublicNumberArticle } from '@/api/articlecenter.js'
const defaultQueryInfo = Object.freeze({
  articleName: '', // 快讯名称
  isApp: false, // 小程序传true，后台传false
  pageNum: 1,
  pageSize: 10,
  sortDirection: 'desc', // 默认(降序：desc)；升序：asc
  sortType: 'update_time' // 排序类型（默认（a.update_time：操作时间）；viewNumber：查看人数；shareNumber：分享人数；）
})
export default {
  name: 'ArticleCenter',
  data() {
    return {
      messageCount: 0,
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0
    }
  },
  created() {
    this.search()
  },
  methods: {
    // 重置节流阀 消息弹窗
    resetMessageCount() {
      this.messageCount++
      setTimeout(() => {
        this.messageCount = 0
      }, 1000)
    },
    // 查询一览
    async search() {
      this.loading = true
      const { data: res } = await articleList(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.$message.error('查询失败!')
        this.loading = false
      }
    },
    // 新增按钮
    addBtn() {
      this.$router.push({
        path: '/datament/addarticle'
      })
    },
    // 编辑按钮
    editBtn(row) {
      if (row.isShelf) {
        // 上架状态不能编辑
        if (this.messageCount === 0) {
          this.resetMessageCount()
          this.$message.warning('上架中的快讯不能编辑~')
        }
      } else {
        this.$router.push({
          path: '/datament/addarticle',
          query: { id: row.id }
        })
      }
    },
    // 上下架
    async setShelfBtn(row) {
      const { id, isShelf, isFreeze } = row
      const txt = isShelf ? '下架' : '上架'
      const query = {
        id,
        isFreeze,
        isShelf: !isShelf
      }
      const { data: res } = await updateType(query)
      if (res.resultCode === 200) {
        this.$message.success(txt + '成功!')
        this.search()
      } else {
        this.$message.error(txt + '失败!')
      }
    },
    // 冻结、解冻
    async setFreezeBtn(row) {
      const { id, isShelf, isFreeze } = row
      const txt = isFreeze ? '解冻' : '冻结'
      const query = {
        id,
        isShelf,
        isFreeze: !isFreeze
      }
      const { data: res } = await updateType(query)
      if (res.resultCode === 200) {
        this.$message.success(txt + '成功!')
        this.search()
      } else {
        this.$message.error(txt + '失败!')
      }
    },
    // 排序前重置排序字段
    resetSort() {
      this.queryInfo.sortType = 'update_time'
      this.queryInfo.sortDirection = 'desc'
    },
    //排序
    sortChanged(val) {
      //val里面返回prop(排序字段)和order(排序方向)
      this.queryInfo.sortType = val.prop
      if (val.order === 'ascending') {
        this.queryInfo.sortDirection = 'asc'
      } else if (val.order === 'descending') {
        this.queryInfo.sortDirection = 'desc'
      } else {
        // 当表格order为'desc'或null时
        this.resetSort()
      }
      this.search()
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
    }
  }
}
</script>


  <style lang="less" scoped>
.article_center {
  width: 100%;
  height: 100%;
  overflow: hidden;

  ::v-deep .up_btn {
    color: #448aff !important;
    border-color: #448aff !important;
  }
  ::v-deep .down_btn {
    color: #75757d !important;
    border-color: #d4d4d4 !important;
  }
  .num_btn {
    color: #448aff;
    text-decoration: underline;
    // cursor: pointer;
  }
  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;

    .input-with-select {
      width: 400px;
      float: right;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .img_box {
    width: 100%;
    height: 48px;
    text-align: center;
    img {
      width: 70px;
      height: 48px;
    }
  }
  // .form_info {
  //   width: 100%;
  //   background: #ffffff;
  //   padding: 20px;
  //   box-sizing: border-box;
  // }
  .tab_txt {
    width: 100%;
    height: 100%;
    background: #ffffff;
    // margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
  }

  .in_table {
    width: 100%;
    height: calc(100% - 120px);
    margin-top: 20px;
    overflow-y: auto;
    /* 滚动条整体部分 */
    &::-webkit-scrollbar {
      width: 3px;
      height: 9px;
    }
    /* 滚动条里面的滑块 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
      transition: background-color 0.3s;
    }

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    text-align: center;
  }
}
</style>
